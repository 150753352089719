import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../../components/layout/layout';
import KnowledgeHero from '../../components/knowledge-hero';
import DynamicComponent from '../../utils/dynamic-component';
import About from '../../components/about';

function KnowledgeDetail({ data, location }) {
  const knowledgeData = data.contentfulPageKnowledge;
  const knowledgeHeroData = {
    title: knowledgeData.title,
    category: knowledgeData.category,
    shortDescription: knowledgeData?.shortDescription.shortDescription,
    embeddedVideo: knowledgeData?.video?.embeddedUrl.embeddedUrl,
    image: knowledgeData?.image,
    updatedAt: knowledgeData?.updatedAt,
    buttons: knowledgeData?.buttons
  };
  const aboutData = {
    logo: knowledgeData.logo.gatsbyImageData,
    title: knowledgeData.knowledgeTitle,
    description: knowledgeData.description
  };
  const Page = 'KNOWLEDGE';

  return (
    //  <h1> Inside Knowledge Details</h1>
    <Layout
      legalFootNote={knowledgeData?.legalFootNote?.legalFootNote}
      location={location}
      pageName={knowledgeHeroData.title}
    >
      <KnowledgeHero data={knowledgeHeroData} />
      <About data={aboutData} value={Page} tags={knowledgeData?.metadata?.tags || []} />
      {data.contentfulPageKnowledge.components
        ?.filter(component => component?.__typename)
        .map(component => (
          <section key={component.id}>
            {DynamicComponent(component.__typename, component)}
          </section>
        ))}
    </Layout>
  );
}

export const data = graphql`
  query KnowledgeDetail($slug: String) {
    contentfulPageKnowledge(slug: {eq: $slug}) {
      metadata {
        tags {
          id
          name
          contentful_id
        }
      }
      name
      title
      slug
      category
      shortDescription{
        shortDescription
      }
      knowledgeTitle
      description {
        raw
      }
      video {
        embeddedUrl {
          embeddedUrl
        }
      }
      logo {
        gatsbyImageData(
          aspectRatio: 1.78
          jpegProgressive: true
          formats: WEBP
          width: 313
          resizingBehavior: SCALE
          placeholder: BLURRED
        )
      }
      image {
        file {
          url
          fileName
        }
        gatsbyImageData(
          aspectRatio: 1.78
          jpegProgressive: true
          formats: WEBP
          width: 730
          quality: 100
          resizingBehavior: SCALE
          placeholder: BLURRED
          cornerRadius: 10
        )
      }
      components {
        ...compContainer
      }
      updatedAt(formatString: "MMMM DD, YYYY")
      legalFootNote {
        legalFootNote
      }
      buttons {
        title
        link {
          ... on ContentfulInternalLink {
            openInANewTab
            entryTitle
            refPage {
              __typename
              ... on ContentfulPageUtility {
                id
                entryTitle
                contentful_id
                type
                slug
              }
            }
          }
          ... on ContentfulCompExternalLink {
            id
            openInANewTab
            title
            url {
              url
            }
          }
        }
      }
    }
  }

  fragment compContainer on ContentfulCompContainer {
    id
    __typename
    backgroundColor
    backgroundImage {
      file {
        url
      }
    }
    rows {
      sys {
        type
        contentType {
          sys {
            id
          }
        }
      }
      title
      entryTitle
      justifyContent
      alignItems
      columns {
        __typename
        isCard
        columnSize
        textColor
        component {
          __typename
          ...accordianComponent
          ...tableComponent
          ...cardComponent
          ...stepperComponent
          ...carouselComponent
          ...imageComponent
          ...richTextWithButton
        }
      }
    }
  }

  fragment accordianComponent on ContentfulCompAccordianContainer {
    id
    accordianTitle
    accordianItems {
      accordianBody {
        raw
      }
      accordianHeader
      __typename
    }
  }

  fragment tableComponent on ContentfulCompTable {
    id
    tableHeader
    entryTitle
    tableRows {
      id
      entryTitle
      cellValues
    }
  }

  fragment stepperComponent on ContentfulCompStepperContainer {
    id
    stepperTitle
    stepperNumber
    stepperItems {
      stepperHeader
      stepperBody {
        raw
      }
      stepperButton {
        title
        link {
          ... on ContentfulInternalLink {
            openInANewTab
            entryTitle
            refPage {
              __typename
              ... on ContentfulPageProgram {
                slug
              }
              ... on ContentfulPageUtility {
                slug
              }
              ... on ContentfulPageEvent {
                slug
              }
              ... on ContentfulPageKnowledge {
                slug
              }
            }
          }
          ... on ContentfulCompExternalLink {
            id
            openInANewTab
            title
            url {
              url
            }
          }
        }
      }
      stepperAssets {
        id
        title
        downloadLink {
          file {
            url
            fileName
          }
        }
        openInANewTab
      }
      stepperImage {
        gatsbyImageData(
          aspectRatio: 1.78
          jpegProgressive: true
          formats: WEBP
          resizingBehavior: SCALE
          placeholder: BLURRED
          cornerRadius: 10
        )
      }
    }
  }

  fragment cardComponent on ContentfulCompRichTextBlock {
    id
    __typename
    entryTitle
    richText {
      raw
    }
  }
  fragment carouselComponent on ContentfulRanCompCarousel {
    slides {
      button {
        ...buttonLinks
      }
      slideLink {
        ...buttonLinks
      }
      date(formatString: "MMMM DD, YYYY")
      entryTitle
      description {
        description
      }
      image {
        gatsbyImageData(
          aspectRatio: 1.78
          jpegProgressive: true
          formats: WEBP
          resizingBehavior: SCALE
          placeholder: BLURRED
        )
      }
      title
      category
    }
    title
  }

  fragment imageComponent on ContentfulCompImageOnly {
    id
    imageTitle
    image {
      file {
        url
        fileName
      }
      gatsbyImageData(
        jpegProgressive: true
        formats: WEBP
        placeholder: BLURRED
      )
    }
  }

  fragment richTextWithButton on ContentfulCompRichTextWithButton {
    heading
    content {
      raw
    }
    button {
      link {
        ... on ContentfulInternalLink {
          openInANewTab
          entryTitle
          refPage {
            __typename
            ... on ContentfulPageProgram {
              slug
            }
            ... on ContentfulPageUtility {
              slug
            }
            ... on ContentfulPageEvent {
              slug
            }
            ... on ContentfulPageKnowledge {
              slug
            }
          }
        }
        ... on ContentfulCompExternalLink {
          id
          openInANewTab
          title
          url {
            url
          }
        }
      }
    }
  }
`;

KnowledgeDetail.propTypes = {
  location: PropTypes.shape.isRequired
};

export default KnowledgeDetail;
